
import { useMarketStore } from '~/stores/market'

export default {
  name: 'FavoriteLinkAnonym',
  setup() {
    const marketStore = useMarketStore()
    return {
      marketStore,
    }
  },
  computed: {
    anonFavoritesCount() {
      return this.marketStore.anonFavoriteList.length
    },
  },
}
